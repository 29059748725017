import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authed: false,
  },
  mutations: {
    userAuthed(state){
      state.authed = true
    },
    userNotAuthed(state){
      state.authed = false
    }
  },
  actions: {
  },
  modules: {
  }
})
