<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: "App",
  components: {
  },
  created(){
    this.$axios.get("https://fileserver01.laidiansetu.com/")
  }
};
</script>

<style>
#app {
  height: 100%;
}
</style>
